import axios from 'axios';

/**
 * 
 * @param {string?} name 
 * @param { 'DEBUG' | 'INFO' | 'WARNING' | 'ERROR' | 'CRITICAL' } level 
 * @param {string} message 
 * @param {string?} funcionName 
 * @param {number?} lineNumber 
 */

async function postLog(serverUrl, logName, level, message, funcionName, lineNumber) {
    logName = logName ? logName : 'Frontend';
    const logBody = {
        name: logName,
        level: level,
        message: message,
        function: funcionName,
        lineNumber: lineNumber
    }
    axios.post(`${serverUrl}/logs/`, logBody, { withCredentials: true })
}

export default postLog;